/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useContext, useEffect, useState } from 'react';

import AccountLayout from '../account/AccountLayout';
import Container from '../components/Container';
import * as Upgrade from '../upgrade';
import { isBrowser } from '../utils';
import * as analytics from '../utils/analytics';
import { useTranslate } from '../utils/getLanguage';
const { STEP } = Upgrade;

export default ({ location: { state = {} } = {}, pageContext }) => {
  analytics.usePageCategory('paketit');
  const translate = useTranslate();
  const { step, startUpgrade } = useContext(Upgrade.Context);
  const [order, setOrder] = useState(null);

  const stateKeys = Object.keys(state || {});
  const hasState = stateKeys.includes('shipmentNumber') || stateKeys.includes('order');

  // use session storage to survive page refresh
  if (!hasState) {
    try {
      state = isBrowser && JSON.parse(window.sessionStorage.getItem('shipmentUpgrade'));
    } catch (err) {
      // no-op
    }
  } else if (hasState && !state.saved) {
    isBrowser && window.sessionStorage.setItem('shipmentUpgrade', JSON.stringify(state));
    state.saved = true;
  }

  useEffect(() => {
    if (state.shipmentNumber) {
      startUpgrade(state.shipmentNumber);
    }
  }, []);

  return (
    <AccountLayout
      title={translate('upgrade.size.title')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
      checkShopDisturbance={true}
    >
      <Container>
        {state && state.order ? (
          <Upgrade.ThankYou order={state.order} />
        ) : step === STEP.SIZE ? (
          <Upgrade.SelectSize />
        ) : step === STEP.SERVICES ? (
          <Upgrade.SelectServices />
        ) : step === STEP.PAYMENT ? (
          <Upgrade.Payment />
        ) : null}
      </Container>
    </AccountLayout>
  );
};
